import { toast } from "react-toastify";
import { getRequest, patchRequest, postRequest, putRequest } from "../../common/utils/request";
import { ATTRIBUTE_VALUES_URL, CREATE_BOX_PRODUCTS_URL, WAREHOUSE_ORDER_GENERAL_URL, FETCH_ORDER_ITEMS_STATUS_CONSTANTS_URL, WAREHOUSE_ORDER_ITEMS_GENERAL_URL, WAREHOUSE_ORDER_SET_IMAGE, WAREHOUSE_GENERATE_BARCODE_SUB_URL, RAAS_ORDER_GENERAL_URL, RAAS_ORDER_ITEM_GENERAL_URL, STATUS_CONSTANTS_URL, ORDER_ITEM_SET_IMAGE, RAAS_ORDER_COMPLETE_ITEM_GENERAL_URL } from "../../common/utils/urlPath";
import { BACK_TO_PRODUCTS_SCREEN, DROP_DOWN_VALUES_SET, NAVIGATE_WIZARD_STEP, ORDER_FETCHED_AND_STEP_FORWARD, PRODUCT_SELECTED, SET_PRODUCT_CATEGORY_STATE, WIZARD_DATA_INITIALIZED } from "./SellOrderWizardActionTypes";
import { CUSTOMER_ORDER_TYPE, RESALE_ORDER_TYPE } from "../../common/utils/commonConstants";
// import zustand_values from '../../mock/zustand_values.json'
// import segment_values from '../../mock/segment_values.json'
// import size_values from '../../mock/size_values.json'
// import age_values from '../../mock/age_values.json'
// import color_values from '../../mock/color_values.json'
// import seller_values from '../../mock/seller_values.json'
// import brand_values from '../../mock/brand_values.json'
// import season_values from '../../mock/season_values.json'
// import material_values from '../../mock/material_values.json'
// import textile_label_values from '../../mock/textile_label_values.json'
// import gender_values from '../../mock/gender_values.json'
// import sustainability_values from '../../mock/sustainability_values.json'
// import land_values from '../../mock/land_values.json'


class SellOrderWizardActions {

    BASE_ORDER_URL = undefined
    BASE_ORDER_ITEM_URL = undefined
    
    constructor(baseOrderUrl, baseOrderItemUrl){
        this.BASE_ORDER_URL = baseOrderUrl;
        this.BASE_ORDER_ITEM_URL = baseOrderItemUrl;
    }

    fetchOrder = (orderId, successCallback, fFaileruCallback) => {
    
        getRequest(`${this.BASE_ORDER_URL}${orderId}/`, successCallback, fFaileruCallback);
    }
    fetchProduct = (productId, successCallback, fFaileruCallback) => {
        
        getRequest(`${this.BASE_ORDER_ITEM_URL}${productId}/`, successCallback, fFaileruCallback);
    }
    
    fetchStatusConstants = (successCallback, fFaileruCallback) => {
        
        getRequest(`${this.BASE_ORDER_ITEM_URL}${STATUS_CONSTANTS_URL}`, successCallback, fFaileruCallback);
    }
    
    createBoxProducts = (iCount, sOrderId, successCallback, fFaileruCallback) => {
        putRequest(`${this.BASE_ORDER_ITEM_URL}${CREATE_BOX_PRODUCTS_URL}${sOrderId}/`, {}, successCallback, fFaileruCallback);
    }
    
    setProductStatus = (orderItemId, status, rejectionMesasge, sInProgressScreen, fSuccessCallback, fFaileruCallback) => {
        const requestBody = {
            pk: orderItemId,
            status: status,
            rejection_message: rejectionMesasge,
            inprogress_screen_index: sInProgressScreen,
            condition_stage_completed: true
        }
        patchRequest(`${this.BASE_ORDER_ITEM_URL}${orderItemId}/?pk=${orderItemId}`, requestBody, fSuccessCallback, fFaileruCallback);
    }
    
    setImage = (sProductId ,image, fileType, isExistingImage, propertyName, shouldRemoveBackground, imageLabel, fSuccessCallback, fFaileruCallback) => {
        const requestBody = {
            is_existing_image: isExistingImage,
            image: image,
            fileType: fileType,
            property_name: propertyName, 
            shouldRemoveBackground: shouldRemoveBackground, 
            imageLabel: imageLabel,
        }
        postRequest(`${this.BASE_ORDER_ITEM_URL}${ORDER_ITEM_SET_IMAGE}${sProductId}/`, requestBody, fSuccessCallback, fFaileruCallback);
    }
    
    updateNextStep = (orderItemId, iInProgressScreen, completionStep, fSuccessCallback, fFaileruCallback) => {
        const requestBody = {
            pk: orderItemId,
            inprogress_screen_index: iInProgressScreen
        }
        if(completionStep){
            requestBody[completionStep] = true;
        }
        patchRequest(`${this.BASE_ORDER_ITEM_URL}${orderItemId}/?pk=${orderItemId}`, requestBody, fSuccessCallback, fFaileruCallback);
    }
    
    saveOrderItem = (sOrderItemId, oOrderItemAttributes, iInProgressScreen, fSuccessCallback, fFaileruCallback) => {
        const requestBody = {
            pk: sOrderItemId,
            inprogress_screen_index: iInProgressScreen,
            ...oOrderItemAttributes
        }
        patchRequest(`${this.BASE_ORDER_ITEM_URL}${sOrderItemId}/?pk=${sOrderItemId}`, requestBody, fSuccessCallback, fFaileruCallback);
    }
    
    setOrderStatus = (sOrderId, sOrderStatus, sExportFormat, fSuccessCallback, fFaileruCallback) => {
        const requestBody = {
            pk: sOrderId,
            status: sOrderStatus,
            export: sExportFormat,
        }
        patchRequest(`${this.BASE_ORDER_URL}save/${sOrderId}/?pk=${sOrderId}/`, requestBody, fSuccessCallback, fFaileruCallback);
    }

    fetchDropDownValues = (successCallback, fFaileruCallback) => {
    
        getRequest(`${this.BASE_ORDER_ITEM_URL}${ATTRIBUTE_VALUES_URL}get/`, successCallback, fFaileruCallback);
    }
    
    addNewFieldValue = (field, value, successCallback, fFaileruCallback) => {
        const requestBody = {
            value: value,
            field, field
        }
        putRequest(`${this.BASE_ORDER_ITEM_URL}${ATTRIBUTE_VALUES_URL}add/`, requestBody, successCallback, fFaileruCallback);
    }
    
    
    generateBarcode = (sProductId, fSuccessCallback, fFaileruCallback) => {
        const requestBody = {}
        postRequest(`${this.BASE_ORDER_ITEM_URL}${WAREHOUSE_GENERATE_BARCODE_SUB_URL}${sProductId}/`, requestBody, fSuccessCallback, fFaileruCallback);
    }
}

const customerOrderActions =  new SellOrderWizardActions(WAREHOUSE_ORDER_GENERAL_URL, WAREHOUSE_ORDER_ITEMS_GENERAL_URL)
const resaleOrderActions = new SellOrderWizardActions(RAAS_ORDER_GENERAL_URL, RAAS_ORDER_COMPLETE_ITEM_GENERAL_URL)

export var RestActions = {}
RestActions[CUSTOMER_ORDER_TYPE] = customerOrderActions;
RestActions[RESALE_ORDER_TYPE] = resaleOrderActions;

export const initializeData = (product, statusConstants, aDropDownValues, currentScreen, currentScreenIndex) => {
    return {
        type: WIZARD_DATA_INITIALIZED,
        order: product.order,
        product: product,
        statusConstants: statusConstants,
        currentScreen: currentScreen,
        currentScreenIndex: currentScreenIndex,
        dropDownValues: aDropDownValues
    }
}

export const orderFetchedAndStepForward = (sNewScreen, iScreenIndex, order, oUpdatedProduct) => {
    return {
        type: ORDER_FETCHED_AND_STEP_FORWARD,
        currentScreen: sNewScreen,
        currentScreenIndex: iScreenIndex,
        order: order,
        selectedProduct: oUpdatedProduct
    }
}

export const productSelected = (oOrderItem, sNewScreen, iScreenIndex) => {
    return {
        type: PRODUCT_SELECTED,
        product: oOrderItem,
        currentScreen: sNewScreen,
        currentScreenIndex: iScreenIndex
    }
}

export const setProductCategoryState = (sProductCategory) => {
    return {
        type: SET_PRODUCT_CATEGORY_STATE,
        productCategory: sProductCategory
    }
}

export const backToProductsScreen = () => {
    return {
        type: BACK_TO_PRODUCTS_SCREEN
    }
}
    
export const setDropDownValues = (aDropDownValues) => {
    return {
        type: DROP_DOWN_VALUES_SET,
        dropDownValues: aDropDownValues
    }
}

export const  navigateWizardStep = (iScreenIndex, sNewScreen) => {
    return {
        type: NAVIGATE_WIZARD_STEP,
        currentScreen: sNewScreen,
        currentScreenIndex: iScreenIndex
    }
}

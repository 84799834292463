import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import AuthService from '../../common/components/services/AuthService';

const HomeScreenWrapper = styled.div`
  display: flex;
  margin: 100px;
  flex-direction: column;
  justify-content: center;
  height: 200px;
  .home-screen-selection-button{
    // color: #405c43;
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
  }
`

const HomeScreen = () => {
  const [userInfo, setUserInfo] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    setUserInfo(AuthService.getUserInfo());
  }, []);
  const hasTradeInAccess = userInfo && ((userInfo.warehouse && userInfo.warehouse['has_trade_in_access']) || (userInfo.type == 'admin'));
  if (userInfo && userInfo.id && !hasTradeInAccess){
    navigate('resale');
  }
  return (
    <HomeScreenWrapper className="home-screen-wrapper">
      {
        hasTradeInAccess && 
        <button onClick={() => {navigate("/sell")}} className="home-screen-selection-button">Trade-In Requests</button>
      }
      <button onClick={() => {navigate("/resale")}} className="home-screen-selection-button">Requests</button>
    </HomeScreenWrapper>
  );
};

export default HomeScreen;

import { getRequest, postRequest, putRequest } from "../../common/utils/request";
import { RAAS_CUSTOMERS_LIST_URL_PATH, RAAS_ORDERS_STATUS_CONSTANTS_URL, RAAS_ORDER_GENERAL_URL, RAAS_ORDER_TYPES_URL } from "../../common/utils/urlPath";
import { ORDERS_FETCHED } from "./RaasOrdersListingScreenActionTypes";


export const fetchOrders = (orderNumberFilter, statusFilter, fSuccessCallback, handleFailure) => {
    
    getRequest(
        `${RAAS_ORDER_GENERAL_URL}?status=${statusFilter}${orderNumberFilter?`&orderNumber=${orderNumberFilter}`:''}`, 
        fSuccessCallback, 
        handleFailure
    );
}

export const fetchCustomers = (fSuccessCallback, handleFailure) => {
    
    getRequest(
        `${RAAS_ORDER_GENERAL_URL}${RAAS_CUSTOMERS_LIST_URL_PATH}`, 
        fSuccessCallback, 
        handleFailure
    );
}

export const createNewOrder = (oCreateData, fSuccessCallback, handleFailure) => {
    
    postRequest(
        `${RAAS_ORDER_GENERAL_URL}`,
        oCreateData,
        fSuccessCallback, 
        handleFailure
    );
}

export const ordersFetched = (orders, statusConstants, orderTypes, userInfo) => {
    return {
        type: ORDERS_FETCHED,
        orders,
        statusConstants,
        orderTypes,
        userInfo
    }
}

export const fetchStatusConstants = (successCallback) => {
    
    getRequest(RAAS_ORDERS_STATUS_CONSTANTS_URL, successCallback);
}

export const fetchOrderTypes = (successCallback) => {
    
    getRequest(RAAS_ORDER_TYPES_URL, successCallback);
}
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { ReactComponent as EditIcon} from "../../static/tildi-edit-icon.svg";
import { ReactComponent as DownExpandArrow} from "../../static/tildi-down-double-arrow-icon.svg";

const CommonDataContainer = styled.div`
  min-height: 70px;
  font-size: 14px;
  color: grey;
  text-align: initial;
  margin-top: 15px;
  margin-bottom: 5px;
  padding: 10px 13px;
  background: #fdf8e2;
  position: relative;
  border: 1px solid black;

  .common-data-edit-icon{
    height: 18px;
    position: absolute;
    top: 10px;
    right: 5px;
  }

  .order-common-data-wrapper{
    display: flex;
    position: relative;
  }

  .order-common-data-edit-buttion{
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .common-data-bottom-toolbar{
    display: flex;
    justify-content: center;
    .common-data-expand-icon{
      height: 12px;
      border-top: 1px solid #dadada;
      transition: all 0.2s ease-out;
      &.expanded{
        transform: rotate(180deg);
        border-bottom: 1px solid #dadada;
        border-top: none;
      }
    }
  }
  &.expanded{
    .common-data.table-cells-wrapper{
      overflow-y: auto;
      max-height: 500px;
    }
    .common-data-bottom-toolbar{
      .common-data-expand-icon{
        transform: rotate(180deg);
        border-bottom: 1px solid #dadada;
        border-top: none;
      }
    }
  }
`

function CommonDataDisplayComponent(props){
  const [isCommonDataExpanded, setCommonDataExpanded] = useState(false);
  return (
    <CommonDataContainer className={`${isCommonDataExpanded ? "expanded" : ""}`}>
      <div className="order-common-data-title">Common Details:</div>
      {props.children}
      <div className="common-data-bottom-toolbar">
        <DownExpandArrow 
          className={`common-data-expand-icon`} 
          onClick={() => {setCommonDataExpanded(!isCommonDataExpanded)}}
        />
      </div>
      <EditIcon className="common-data-edit-icon" onClick={() => {if(props.onEditCommonData){props.onEditCommonData()}}}/>
    </CommonDataContainer>
  );

}

CommonDataDisplayComponent.propTypes = {
  onEditCommonData: PropTypes.func,
};

export default CommonDataDisplayComponent;
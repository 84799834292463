import { getRequest } from "../../common/utils/request";
import { FETCH_ORDERS_STATUS_CONSTANTS_URL, WAREHOUSE_ORDER_GENERAL_URL } from "../../common/utils/urlPath";
import { ORDERS_FETCHED } from "./SellOrdersListingScreenActionTypes";


export const fetchOrders = (orderNumberFilter, statusFilter, fSuccessCallback, handleFailure) => {
    
    getRequest(
        `${WAREHOUSE_ORDER_GENERAL_URL}?status=${statusFilter}${orderNumberFilter?`&orderNumber=${orderNumberFilter}`:''}`, 
        fSuccessCallback, 
        handleFailure
    );
}

export const ordersFetched = (orders, statusConstants) => {
    return {
        type: ORDERS_FETCHED,
        orders: orders,
        statusConstants: statusConstants
    }
}

export const fetchStatusConstants = (successCallback) => {
    
    getRequest(FETCH_ORDERS_STATUS_CONSTANTS_URL, successCallback);
}
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import BottomRightOperationTools from './BottomRightOperationTools';
import CommonTreeDropDownField from './CommonTreeDropDownField';
import CommonDropDownField from './CommonDropDownField';
import { ATTRIBUTE_TYPE_DROP_DOWN, ATTRIBUTE_TYPE_TEXT_AREA } from '../utils/commonConstants';
import CommonTextArea from './CommonTextArea';
import { ReactComponent as BackArrow} from "../../static/tildi-double-left-arrow-icon.svg";

const BackButton = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;
  position: absolute;
  top: 0;
  padding: 3px 10px 3px 5px;
  background: #405c43;
  color: white;
  border-radius: 15px;
  font-weight: bold;
  .back-icon{
    height: 18px;
  }
`

const CommonDataEditWindow = styled.div`
  min-width: 97vw;
  min-height: 90vh;
  background-color: white;
  position: absolute;
  top: 0px;
  left: -5px;
  z-index: 999;
  padding-bottom: 100px;

  .common-data-window-title{
    font-weight: bold;
    font-size: 20px;
    text-align: center;
  }
  .common-data-properties-wrapper{
    width: 90%;
    margin: 20px auto;
    .common-data-property-field{
      max-height: 200px;
    }
  }
`

const getPropertyDisplayField = (property, propertyValue, values, onChange) => {
  switch(property.type){
    case ATTRIBUTE_TYPE_DROP_DOWN:
      return (
        <CommonDropDownField
          id={property.id}
          label={property.label + (property.isMandatory ? "*" : "")}
          onSelect={onChange}
          options={values}
          isMultiSelect={property.isMultiselect}
          canAddNew={property.canAdd}
          value={ propertyValue && propertyValue.length ? propertyValue : "" }
          onAdd={onChange}
          canEmpty={true}
          key={`common-property-drop-down-${property.id}`}
        />
      )
      case ATTRIBUTE_TYPE_TEXT_AREA:
        return (
          <CommonTextArea
            id={property.id}
            label={property.label + (property.isMandatory ? "*" : "")}
            onChange={(event) => {onChange(event.target.value)}}
            value={ propertyValue && propertyValue.length ? propertyValue : "" }
            key={`common-property-text-area-${property.id}`}
          />
        )
    default:
      return "";
  } 
}

function CommonDataEditScreen(props){
  const [commonDataEditProperties, setCommonDataEditProperties] = useState({});
  const setCommonDataValue = (propertyId, propertyValue) => {
    var newCommonEditProperties = {...commonDataEditProperties};
    newCommonEditProperties[propertyId] = propertyValue;
    setCommonDataEditProperties(newCommonEditProperties);
  }
  return (
    <CommonDataEditWindow>
      <BackButton onClick={props.onCancelCommonDataEdit}>
        <BackArrow className="back-icon"/>Cancel
      </BackButton>
      <div className="common-data-window-title">Order Common Data</div>
      <div className="common-data-properties-wrapper">
        {
          props.isCommonCategoryToBeUpdated && 
          <CommonTreeDropDownField 
            className="common-data-property-field"
            id="category"
            label="Category"
            onSelect={(value) => {
              setCommonDataValue('category', value);
            }}
            options={Object.keys(props.dropDownValues).length ? props.dropDownValues.category : []}
            isMultiselect={false}
            value={commonDataEditProperties['category'] !== undefined ? commonDataEditProperties['category'] : props.commonDataFromOrder && props.commonDataFromOrder['category']}
            canEmpty={true}
          />
        }
        {
          props.commonProperties.map((commonProperty) => {
            var commonDataInOrder = props.commonDataFromOrder && props.commonDataFromOrder[commonProperty.id];
            var isEdited = commonProperty.id in commonDataEditProperties;
            var editedData = commonDataEditProperties[commonProperty.id];
            return getPropertyDisplayField(
              commonProperty, 
              isEdited ? editedData : commonDataInOrder, 
              props.dropDownValues[commonProperty.id], 
              (value) => {
                setCommonDataValue(commonProperty.id, value);
              }
            );
          })
        }
      </div>
      <BottomRightOperationTools
        showAddButton={false}
        showCompleteButton={true}
        onClickComplete={() => {props.onCompleteCommonDataEdit(commonDataEditProperties)}}
      />
    </CommonDataEditWindow>
  );

}

CommonDataEditScreen.propTypes = {
  commonDataFromOrder: PropTypes.object,
  commonProperties: PropTypes.array.isRequired,
  dropDownValues: PropTypes.object.isRequired,
  onCompleteCommonDataEdit: PropTypes.func.isRequired,
  onCancelCommonDataEdit: PropTypes.func,
  isCommonCategoryToBeUpdated: PropTypes.bool,
};

export default CommonDataEditScreen;